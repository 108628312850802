import classNames from "classnames";

import classes from "./PageOauth.module.pcss";
import {classPrefix, createStylesSelector, queryStringify, sanitizePath, useOnMountUnsafe} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router";
import type {ErrorMessage as ErrorMessageType, ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {useLazyGetAuthOauthByProviderAndModeQuery, usePostAuthSignInMutation} from "~/api/enhancedAuthAPI";
import ErrorMessage from "~/components/common/ErrorMessage";
import Loader from "~/components/common/Loader";
import {useSelector} from "react-redux";
import {getSlugInfo} from "~/reducers/navigation";
import {Slug} from "~/config";
import Link from "~/components/common/Link";

export default function PageOauth({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const {path: accountPath} = useSelector(getSlugInfo)(Slug.Account);

    const [redirectURL, setRedirectURL] = useState<string>("");

    const [error, setError] = useState<ErrorMessageProps["error"]>("");

    const params = useParams();
    const [searchParams] = useSearchParams();
    const provider = params.provider;
    const queryError = searchParams.get("error");
    const email = searchParams.get("email");
    const retURL = searchParams.get("_ret") || "";

    const oauthProviderToken = {
        provider: searchParams.get("oauth-provider-token[provider]"),
        token: searchParams.get("oauth-provider-token[token]"),
    };
    const noRedirect = searchParams.get("no-redirect") === "yes";

    const [getAuthOauthByProviderAndMode, {isLoading: oauthLoading, error: oauthLocationError}] = useLazyGetAuthOauthByProviderAndModeQuery();
    const [postAuthSignIn, {isLoading: signInLoading, error: signInError}] = usePostAuthSignInMutation({});

    const navigate = useNavigate();

    useOnMountUnsafe(() => {
        if (!queryError) {
            if (provider) {
                if (!oauthProviderToken.token && !oauthProviderToken.provider && !email) {
                    (async () => {
                        const {data: oauthData, isError, error: oauthError} = await getAuthOauthByProviderAndMode({
                            mode: "authorize",
                            provider,
                            host: window.location.host,
                            "query_no-redirect": "once",
                            "ret-url": retURL,
                        });
                        if (isError) {
                            if (typeof oauthError === "object") {
                                setError(oauthError as Error);
                            } else {
                                setError("API error");
                            }
                        } else if (oauthData) {
                            if (oauthData.location) {
                                if (!noRedirect) {
                                    window.location.href = oauthData.location;
                                }
                                setRedirectURL(oauthData.location);
                            } else {
                                setError("invite is inactive");
                            }
                        }
                    })();
                } else if (email && oauthProviderToken.token && oauthProviderToken.provider) {
                    (async () => {
                        const {error: signInError} = await postAuthSignIn({
                            email: email,
                            "oauth-provider-token": oauthProviderToken
                        });
                        if (!signInError) {
                            const navURL = sanitizePath(retURL || accountPath + "/?" + queryStringify({oauth: provider}));
                            if (!noRedirect) {
                                navigate(navURL);
                            }
                            setRedirectURL(navURL);
                        }
                    })();
                } else {
                    setError("invalid query data");
                }
            } else {
                setError("invalid provider data");
            }
        }
    }, [provider, queryError, oauthProviderToken.provider, oauthProviderToken.token]);

    const isLoading = oauthLoading || signInLoading;
    const errors:ErrorMessageType[] = [
        error as ErrorMessageType,
        oauthLocationError as ErrorMessageType,
        signInError as ErrorMessageType,
        queryError as ErrorMessageType,
    ].filter(v => !!v);

    return <section className={classNames(classPrefix("page-oauth"), propsClassName, styles("page-oauth"))}>
        <h1>OAuth Authentication</h1>
        {errors.length
            ? <ErrorMessage styles={styles} prefix="Error: " error={errors} />
            : (isLoading || !redirectURL
                ? <Loader styles={styles} size="large"/>
                : <div className={styles && styles("oauth-result").toString()}>
                    <p>You&#39;ll be redirected automatically.</p>
                    <p>If it doesn&#39;t happen please follow this link <Link to={redirectURL} native>{redirectURL}</Link></p>
                </div>
            )}
    </section>;
}