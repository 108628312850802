import classNames from "classnames";

import classes from "./PageAccount.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {useSelector} from "react-redux";
import {getPageSlug, getPageTitle} from "~/reducers/page";
import Menu from "~/components/common/Menu";
import Block from "~/components/common/Block";
import {Slug} from "~/config";

function AccountSection({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    const slug = useSelector(getPageSlug) || Slug.Account;
    return <div className={classNames(classPrefix("account-section"), propsClassName, styles("account-section"))}>
        {"page-" + slug}
    </div>;
}

export default function PageAccount({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const slug = useSelector(getPageSlug) || Slug.Account;
    const title = useSelector(getPageTitle) ;

    return <section className={classNames(classPrefix("page-account"), propsClassName, styles("page-account"))}>
        <h1>{title}</h1>
        <div className={"" + styles("page-content", "with-sidebar")}>
            <nav className={"" + styles("sidebar")}>
                <Menu
                    styles={styles}
                    name="account"
                    mode="inline"
                    className={"" + styles("sidebar-menu")}
                    subMenuClassName={"" + styles("sidebar-sub-menu")}
                    defaultOpenKeys={[slug]}
                    openKeys={[slug]}
                    defaultSelectedKeys={[slug]}
                    inheritKeyPrefix={false}
                    slugAsKey={true}
                />
            </nav>
            <Block
                className={"" + styles("account-data")}
                styles={styles}
                header={false}
            >
                <AccountSection className={styles("account-section")} styles={styles} classes={propsClasses}/>
            </Block>
        </div>
    </section>;
}