import classNames from "classnames";

import classes from "./PageLogin.module.pcss";
import {classPrefix, createStylesSelector, sanitizePath} from "~/lib";
import type {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {useNavigate, useSearchParams} from "react-router";
import {usePostAuthSignInMutation} from "~/api/enhancedAuthAPI";
import {FORM_ERROR} from "final-form";
import Link from "~/components/common/Link";
import LoginForm from "~/components/forms/LoginForm";
import type {LoginValues} from "~/@types/components/forms/LoginFormProps";
import {Slug} from "~/config";
import {useSelector} from "react-redux";
import {getSlugInfo} from "~/reducers/navigation";
import {getCurrentUser} from "~/reducers/account";
import OAuthSignIn from "~/components/templates/OAuthSignIn";


export default function PageLogin({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const [searchParams] = useSearchParams();

    const currentUser = useSelector(getCurrentUser);

    const {path: accountPath} = useSelector(getSlugInfo)(Slug.Account);

    let retURL = searchParams.get("_ret") || accountPath || "";

    const initialValues: LoginValues = {
        email: searchParams.get("email") || undefined,
        "long-term-token": true,
        "ret-url": retURL,
    };

    const navigate = useNavigate();

    const [postAuthSignIn, postAuthSignInProps] = usePostAuthSignInMutation({});

    const loginHandle = async (values: LoginValues) => {
        const resp = await postAuthSignIn({
            email: values["email"] || "",
            password: values["password"] || "",
            "long-term-token": values["long-term-token"],
            "logout-other": values["logout-other"],
            "ret-url": retURL,
            "current-user": values["current-user"],
        });
        if (resp.error) {
            return {[FORM_ERROR]: resp.error};
        } else if (resp.data) {
            if (!retURL && resp.data["ret-url"]) {
                retURL = resp.data["ret-url"];
            }
            navigate(sanitizePath(retURL));
        }
    };

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-login"), propsClassName, styles("page-login"))}>
        <h1>Login</h1>
        <LoginForm
            styles={styles}
            className={styles("login-form").toString()}
            initialValues={initialValues}
            onSubmit={loginHandle}
            loading={postAuthSignInProps.isLoading}
            currentUser={currentUser}
        />
        <ul className={styles("links").toString()}>
            <OAuthSignIn styles={styles} Component="li"/>
            <li>Don&lsquo;t have an account? <Link styles={styles} slug={Slug.Signup} ico={{ico: "Edit"}} icoPosition="after">Join for free</Link></li>
            <li>Forgot password? We will help <Link styles={styles} slug={Slug.Restore} ico={{ico: "Unlock"}} icoPosition="after">restore it</Link></li>
        </ul>
    </section>;
}
